<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

  export default {

  }
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&display=swap');

body {
  font-family: 'DM Sans', sans-serif;
}
</style>