let API_BASE_URL;

if (window.location.hostname === 'restaurantessolidarios.es' || window.location.hostname === 'www.restaurantessolidarios.es') {
    API_BASE_URL = 'https://restaurantessolidarios.es:8081';
} else if (window.location.hostname === 'restaurantessolidarios.com' || window.location.hostname === 'www.restaurantessolidarios.com') {
    API_BASE_URL = 'https://restaurantessolidarios.com:8081';
} else if (window.location.hostname === 'restaurantessolidarios.org' || window.location.hostname === 'www.restaurantessolidarios.org') {
    API_BASE_URL = 'https://restaurantessolidarios.org:8081';
} else {
    API_BASE_URL = 'https://restaurantessolidarios.es:8081'; // valor por defecto
}

export { API_BASE_URL };
